.App {
  text-align: center;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MuiInputBase-readOnly {
  cursor:not-allowed;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}
